import {css} from 'styled-components';

export default css`
  /* #rev-GlobalSpinner {
  } */

  #rev-ProductListPage .rev-FilterMenuL2,
  #rev-ProductDetailPage .rev-FilterMenuL2 {
    position: sticky;
    top: 72px;
    margin: 0px 10px;

    & > .header {
      padding: 23px 0px;
      font-size: 1.25rem;

      & > .label {
        display: none;
      }

      &::before {
        content: '產品列表';
        flex: 1;
        font-size: 1.3rem;
      }
    }

    & > .L1 {
      background-color: transparent;
      border-radius: 0px;
      padding: 0px;
      margin-bottom: 0px;
      box-shadow: none;

      & > .header {
        padding: 10px 0;
        border-top: 1px solid #d0d0d0;
        font-size: 1rem;
        color: #000000;
      }

      & > .L1-items {
        border-top: 1px solid #d0d0d0;
        padding-left: 15px;
        overflow: hidden;

        & > .L2 {
          padding: 5px 10px;
        }

        & > .L2 > .header {
          font-size: 0.95rem;
          color: #6f6f6f;
        }

        & > .L2.selected > .header {
          font-size: 0.95rem;
          color: var(--primaryColor);
        }
      }

      &:last-child {
        border-bottom: 1px solid #d0d0d0;
      }
    }
  }

  /*  __CODEGEN_START__ */ 
  --contentMaxWith: 1200px;
  --basePadding: 20px;
  --contentTextSize: 13px;
  --contentTextColor: #000000;
  --contentTextLetterSpacing: 2px;
  --primaryColor: #71c6d0;
  --sectionPadding: 50px 100px;
  --sectionMobilePadding: 20px;

  #rev-ArticleListPage .rev-FilterMenuL1 {
    display: none;
  }

  #rev-ArticleListPage .browser-header {
    & > img {
      height: 550px;
      width: 100%;
    }
  }

  #rev-SiteNavBar > .maxwidth-container {
    & > .logo-box > a > img {
      display: none;
    }

    & > .logo-box > a::before {
      content: '';
      display: inline-block;
      background-image: url('/images/NavLogo.png');
      background-size: 154px 50px;
      width: 154px;
      height: 50px;
    }

    & > .nav-items {
      justify-content: flex-end;

      & > * {
        letter-spacing: 2px;
        color: #000000;
      }
    }
  }
  

  #rev-SiteNavBar .nav-round-btn-box,
  #rev-SiteNavBar .nav-icon-btn-box {
    display: none;
  }

  #rev-AdminLandingPage .major-statistic .user,
  #rev-AdminLandingPage .major-statistic .product,
  #rev-AdminLandingPage .major-statistic .order,
  #rev-AdminLandingPage .order-statistic {
    display: none;
  }

  #rev-LandingPage,
  #rev-AboutPage,
  #rev-FoodPage,
  #rev-RoomPage,
  #rev-ServicePage {
    .section {
      padding: var(--sectionPadding);
      @media screen and (max-width: 768px) {
        padding: var(--sectionMobilePadding);
      }
    }

    .carousel-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    .read-more-btn {
      padding: 10px 14px;
      background-color: #eaeaea;
      border-radius: 10px;
      cursor: pointer;

      & > p {
        margin-bottom: 0px;
      }
    }

    .wrap {
      flex-wrap: wrap;
    }

    .pj-row {
      display: flex;
      flex-direction: row;
      & > img {
        /* border-radius: 20px; */
        width: 50%;
        height: 450px;
      }
      .tab {
        display: flex;
        padding: 0px 10px;
        justify-content: center;
        align-items: center;
        margin-right: 25px;
        width: 150px;
        height: 40px;
        background-color: #eaeaea;
        border-radius: 10px;
        cursor: pointer;

        @media screen and (max-width: 768px) {
          margin-right: 10px;
        }

        p {
          margin-bottom: 0px;
          color: #000;
          font-size: 1rem;
          @media screen and (max-width: 768px) {
            font-size: 0.95rem;
          }
        }
        :last-child {
          margin-right: 0px;
        }
      }
      .selected {
        background-color: var(--primaryColor);
        p {
          color: white;
        }
      }
    }

    .section-dark {
      padding: var(--sectionPadding);
      background-color: #f9f8f7;

      @media screen and (max-width: 768px) {
        padding: var(--sectionMobilePadding);
      }
    }

    .section-content-wrapper {
      padding: 50px 0px;
      display: flex;
    }

    .content {
      letter-spacing: 2px;
      line-height: 1.8rem;
      white-space: break-spaces;
      color: #000;
      font-size: 1rem;

      @media screen and (max-width: 768px) {
        font-size: 0.95rem;
      }
    }

    .centered {
      margin: 0 auto;
      text-align: center;
    }

    .title {
      font-size: 1.5rem;
      color: var(--primaryColor);
      letter-spacing: 2px;
      font-weight: bold;
      @media screen and (max-width: 768px) {
        font-size: 1.2rem;
      }
    }

    .subtitle {
      margin-bottom: 10px;
      color: var(--primaryColor);
      font-size: 1.2rem;
      letter-spacing: 2px;
      font-weight: bold;
      @media screen and (max-width: 768px) {
        font-size: 0.95rem;
      }
    }

    .grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    .grid-item {
      margin-top: 30px;
      cursor: pointer;
      max-width: calc((100% - 200px - 40px) / 3);
      @media screen and (max-width: 768px) {
        max-width: none;
      }
    }
    .grid-item-img {
      height: 250px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 20px;
    }
    .grid-item-date {
      color: #666666;
      font-size: 0.8rem;
      letter-spacing: 2px;
    }
    .grid-item-title {
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 2.57px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .grid-item-outline {
      font-size: 0.95rem;
      letter-spacing: 2px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .scroll-constrain {
      width: 100vw;
      overflow-x: auto;
      padding: 10px 20px 100px 20px;
    }

    .scroll-wrapper {
      display: flex;
    }
    .scroll-item {
      text-align: center;
      padding: 10px;
      margin-right: 20px;
      border-radius: 20px;
      min-width: 350px;
      box-shadow: 0 10px 15px rgba(138, 149, 158, 0.1),
        0 6px 6px rgba(138, 149, 158, 0.1);
    }

    .scroll-item-img {
      height: 250px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 20px;
    }

    .scroll-item-subtitle {
      color: #666666;
      font-size: 1rem;
      letter-spacing: 2px;
    }
    .scroll-item-title {
      font-size: 18px;
      letter-spacing: 2.57px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
/* __CODEGEN_END__  */
`;
