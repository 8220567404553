import {getOutlet} from 'reconnect.js';
import Config from '../../../data.json';

export function getAdminRoutes() {
  const config = getOutlet('config');

  return [
    {name: '首頁', path: '/admin/'},
    {
      name: '文章',
      path: '__group-article',
      subroutes: [
        {name: '文章全覽', path: '/admin/articles/'},
        {name: '多語系(Beta)', path: '/admin/i18n-articles'},
      ],
    },
    {name: '網站設定', path: '/admin/site/'},
    {name: '頁面建置(Beta)', path: '/admin/pages'},
    {name: '前台手動更新', path: '/admin/rebuild-history'},
    {
      name: '上傳工具',
      path: '__group-uploader',
      subroutes: [
        {name: '圖片上傳', path: 'image-uploader'},
        {name: '檔案上傳', path: 'file-uploader'},
      ],
    },
    {name: '重設密碼', path: 'reset-password'},
    {name: '登出', path: 'logout'},
  ]
    .filter(
      (_) =>
        !!_ &&
        (_.path === '/admin/rebuild-history'
          ? Config.allowGithubRebuild
          : true),
    )
    .map((route) => {
      if (route.name === '優惠' && !!config.getValue()) {
        let combine_discount_index = route.subroutes.findIndex(
          (subroute) => subroute.name === '組合折扣',
        );

        route.subroutes[
          combine_discount_index
        ].path = `/admin/combine-discount/?action=detail&id=${
          config.getValue()._id.$oid
        }`;
      }

      return route;
    });
}